import React, { useMemo, useState, useCallback } from 'react';

import { StyledBaseInputProps } from './BaseInput';
import { BaseProps } from '../form/types';
import { generateRandomNumber } from 'utils/general';

import {
  ModifiedCircle,
  Indicators,
  StyledInfo,
  StyledWarning,
  StyledError,
  InfoWrapper,
  TooltipWrapper,
  WarningWrapper,
  ErrorWrapper
} from '../form/FormField.styles';

import {
  Wrapper,
  StyledCheckbox,
  StyledLabel,
  StyledSpan
} from './Checkbox.styles';

export interface CheckboxProps extends Omit<StyledBaseInputProps, 'errored'> {
  width?: number;    // will be converted to rem
  widthM?: string;
  widthML?: string;
  widthT?: string;
  widthL?: string;
  format?: (value: any) => any;
  labelPos?: 'top' | 'right' | 'bottom' | 'left';
  info?: string;        // NOTE: have either info or tooltip but not both
  tooltip?: string;    // NOTE: have either info or tooltip but not both
  warning?: string;
  hasChanged?: boolean;
  smallText?: boolean;
  noIcons?: boolean;
  noErrorText?: boolean;
  onEnter?: (checked: boolean) => void;
}

export type Props = CheckboxProps & Omit<BaseProps, 'width'>;

const defaultWidth: number = 2;
const tickWidthRatio: number = .3;
const tickHeightRatio: number = .575;

const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type,
    className,
    label,
    error,
    highlightChanged,
    width,
    widthM,
    widthML,
    widthT,
    widthL,
    noWidth,
    labelPos,
    info,
    tooltip,
    warning,
    hasChanged,
    smallText,
    noIcons,
    noErrorText,
    onFocus,
    onBlur,
    onEnter,
    ...rest
  } = props;

  const [showMobileTooltip, setShowMobileTooltip] = useState<boolean>(false);
  const formattedError = useMemo(() => {
    return error?.split('').map((char, i) => i === 0 ? char.toUpperCase() : char).join('');
  }, [error]);
  const id = useMemo(() => generateRandomNumber(1000, 9999), []);

  const tooltipIconHandler = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();

    setShowMobileTooltip(prevState => !prevState);
  }, []);

  const root = document.documentElement;
  root.style.setProperty('--checkbox-width', `${(width || defaultWidth) * tickWidthRatio}rem`);
  root.style.setProperty('--checkbox-height', `${(width || defaultWidth) * tickHeightRatio}rem`);

  const shouldShowMobileTooltip: boolean = !!tooltip && showMobileTooltip;

  return (
    <Wrapper
      widthM={widthM}
      widthML={widthML}
      widthT={widthT}
      widthL={widthL}
      style={rest.style}
      tabIndex={0}
      data-title={tooltip || null}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          const input = (e.target as HTMLElement).querySelector('input');

          if (input) {
            input.checked = !input.checked;

            if (onEnter) {
              // Note: Fix as prop type is for Input element
              onEnter(input.checked);
            }
          }
        }
      }}
    >
      <StyledCheckbox
        {...rest}
        ref={ref}
        id={`${id}`}
        type='checkbox'
        checked={rest.checked}
        errored={!!error}
        width={width || defaultWidth}
        tickWidthRatio={tickWidthRatio}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <StyledLabel
        htmlFor={`${id}`}
        labelPos={labelPos}
        smallText={smallText}
      >
        <StyledSpan
          width={width || defaultWidth}
        ></StyledSpan>
        {label}
        {!noIcons && (
          <Indicators>
            {hasChanged && <ModifiedCircle />}
            {warning && <StyledWarning />}
            {error && <StyledError />}
            {tooltip && (
              <StyledInfo onClick={tooltipIconHandler}/>
            )}
          </Indicators>
        )}
      </StyledLabel>

      {info && (
        <InfoWrapper>
          {info}
        </InfoWrapper>
      )}

      {shouldShowMobileTooltip && (
        <TooltipWrapper>
          {tooltip}
        </TooltipWrapper>
      )}

      {warning && (
        <WarningWrapper>
          {warning}
        </WarningWrapper>
      )}

      {error && !noErrorText && (
        <ErrorWrapper>
          {formattedError}
        </ErrorWrapper>
      )}
    </Wrapper>
  );
});

export default Checkbox;

