import styled from 'styled-components';

import {
  BaseButtonStyles,
  BaseContentWrapperStyles
} from './mixins.styles';

export const StyledButton = styled.button<{
  $width?: string;
  $loading?: boolean,
  iconOnly?: boolean;
  noPadding?: boolean;
  inline?: boolean;
}>`
  ${({ $width, $loading, iconOnly, noPadding, inline }) => BaseButtonStyles($width, $loading, iconOnly, noPadding, inline)}
`

export const ContentWrapper = styled.div`
  ${BaseContentWrapperStyles()}
`

