import React, { FC, memo } from 'react';

import {
  Background,
  Footer
} from 'components/molecules';
import { StyledLink } from 'theme/mixins';

import {
  Wrapper,
  StyledCard,
  FormLine,
  StyledHeader,
  HR
} from './PostSignup.styles';

const PostSignup: FC<any> = props => {
  return (
    <>
      <Background />
      <Wrapper>
        <StyledCard boxShadow>
          <FormLine
            center
            marginBottom
          >
            <StyledHeader>Verify your account</StyledHeader>
          </FormLine>

          <FormLine marginBottom>
            <HR />
          </FormLine>
          <FormLine marginBottom />

          <FormLine marginBottom>
            <p>Please check your email and verify your account to get started!</p>
          </FormLine>

          <FormLine marginBottom />

          <FormLine
            center
            marginBottom
          >
            <span>Already verified?&nbsp;</span>
            <StyledLink to={'/login'}>Login</StyledLink>
          </FormLine>
        </StyledCard>
        <FormLine center>
          <Footer />
        </FormLine>
      </Wrapper>
    </>
  );
};

export default memo(PostSignup);
