import React, { FC, memo, useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core'
import {
  format,
  isSameMonth,
  startOfMonth
} from 'date-fns';
import {
  object,
  string,
  number,
  array,
  mixed,
  AnyObject
} from 'yup';

import { NetworkError } from 'types/Error';
import { PaymentOption } from 'types/Enums';
import {
  formatCurrency,
  getRawCurrencyValue,
  animateValue,
  isInIframe,
  isFieldCalculable,
  hasValue,
  getNetworkErrors,
  getFlattenFormFields,
  getBookingInvoiceNumber,
  canCustomerMakePayment
} from 'utils/general';
import {
  BrowserWindow,
  DragItem
} from 'types/UI';

import { theme } from 'theme';
import { gatewayService } from 'services';
import { Step, Field, FieldType } from 'types/Service';
import {
  FormSection,
  TextInput,
  TextArea,
  NumericInput,
  Dropdown,
  Button,
  PrimaryButton,
  Spinner,
  Billing as BillingIcon,
  Bank as BankIcon,
  Text
} from 'components/atoms';
import { ProgressStep } from 'components/molecules/Progress/Progress';
import { Actions, FrameActions } from 'state';
import {
  Background,
  Progress,
  DateTimeFields,
  PostcodeLookup,
  Footer
} from 'components/molecules';
import Payment from './components/payment/Payment';
import {
  useDebouncedCallback,
  useValidation
} from 'components/hooks';
import { BookingPageProps } from 'components/AppRouter';
import { ISO_FORMAT } from '../../../constants';
import { IconWrapper } from 'theme/mixins';
import ErrorPage from '../error/Error';

import {
  Wrapper,
  InnerWrapper,
  Header2,
  BookingFormWrapper,
  FormLine,
  Card,
  HR,
  AccountDetails
} from './Booking.styles';

interface DraggableFormFieldProps {
  isPreview?: boolean;
  item?: any;
  content?: React.ReactNode;
  canDrag?: boolean;
  fieldItem?: Field;
  index?: number;
  stepIndex?: number;
  onReOrder?: (dragIndex: number, hoverIndex: number) => void;
  onDrag?: (field: Field) => void;
  onEnd?: (field: Field, stepIndex: number) => void;
}

export interface DropItemProps {
  content: React.ReactNode;
  fieldItem: Field;
  width: number;
  index: number;
  stepIndex: number;
  type: DragItem;
}

export const DraggableFormField: FC<DraggableFormFieldProps> = (props) => {
  const {
    isPreview,
    item,
    content,
    canDrag,
    fieldItem,
    index,
    stepIndex,
    onReOrder,
    onDrag,
    onEnd
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: DragItem.Field,
    canDrag,
    item: () => ({
      content,
      fieldItem,
      width: ref.current!.getBoundingClientRect().width,
      index,
      stepIndex
    }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dragItem) => {
      if (onEnd && dragItem.fieldItem) {
        onEnd(dragItem.fieldItem, stepIndex as number);
      }
    }
  });

  const [{ handlerId }, drop] = useDrop<
    DropItemProps,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DragItem.Field,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(hoverItem: DropItemProps, monitor) {
      if (!ref.current) {
        return
      }

      const dragStepIndex: number = hoverItem.stepIndex;
      const hoverStepIndex: number = stepIndex || 0;
      const isDomesticReOrder: boolean = dragStepIndex === hoverStepIndex;
      const dragIndex = hoverItem.index;
      const hoverIndex: number = hasValue(index) ? index as number : -1;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex! && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex! && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      if (!isPreview && isDomesticReOrder && onReOrder) {
        onReOrder(dragIndex, hoverIndex!);

        // mutation for performance
        hoverItem.index = hoverIndex
      }
    },
  });

  drag(drop(ref));

  if (isDragging && onDrag && fieldItem) {
    onDrag(fieldItem);
  }

  return (
    <FormLine
      ref={ref}
      marginBottom
      column
      data-handler-id={handlerId}
      style={{
        opacity: isDragging ? 0 : 1,
        userSelect: 'none'
      }}
    >
      {isPreview ? item.content : content}
    </FormLine>
  );
};

export interface State {
  unavailableDates: {
    loading: boolean;
    data: string[] | undefined;
    error: NetworkError | null;
  };
  timeSlots: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  job: {
    loading: boolean;
    data: string[] | null;
    error: NetworkError | null;
  };
  calculation: {
    loading: boolean;
    data: any;
    error: NetworkError | null;
  };
  // TODO: look to remove and rely on paymentIntent.data
  paymentNeeded: number | null;
  previewPrice: number;
  paymentIntent: {
    loading: boolean;
    data: any | null;
    error: NetworkError | null;
  };
  // TODO: change to upsertJobId
  upsertJobId: string | null;
  chosenPaymentOption: PaymentOption;
};

const initialState: State = {
  unavailableDates: {
    loading: false,
    data: undefined,
    error: null
  },
  timeSlots: {
    loading: false,
    data: null,
    error: null
  },
  job: {
    loading: false,
    data: null,
    error: null
  },
  calculation: {
    loading: false,
    data: null,
    error: null
  },
  paymentNeeded: null,
  previewPrice: 0,
  paymentIntent: {
    loading: false,
    data: undefined,
    error: null
  },
  upsertJobId: null,
  chosenPaymentOption: PaymentOption.None
};

let firstLoad: boolean = true;

const getPaymentOptionIcon = (paymentOption: PaymentOption): JSX.Element | null => {
  switch (paymentOption) {
    case PaymentOption.CustomerPaymentMethod:
      return <BillingIcon />;
    case PaymentOption.ACHTransfer:
      return <BankIcon />;
    default:
      return null;
  };
};

const Booking: FC<BookingPageProps> = props => {
  const {
    step,
    index,
    serviceId,
    jobId,
    state: globalState,
    dispatch
  } = props;

  const floatingLabelRef = useRef<HTMLDivElement>(null);

  // console.log('------------------globalState', index, globalState);

  const [state, setState] = useState<State>({ ...initialState });
  const [prevParentReady, setPrevParentReady] = useState<null | boolean>(false);

  const navigate = useNavigate();
  const params: any = useParams();

  const atStart = useMemo(() => index === 1, [index]);
  const currentService = useMemo(() => {
    if (props.state.service.data) {
      return props.state.service.data;
    }

    return null;
  }, [props.state.service]);

  const {
    errors,
    validate,
    validateSchema,
    setSchema,
    reset
  } = useValidation();

  const stepHasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

  const progressSteps = useMemo(() => {
    return globalState.service.data!.steps.map((serviceStep: Step, i: number) => {
      return {
        name: serviceStep.name,
        label: serviceStep.label,
        isModifiable: isInIframe(window) && (window as BrowserWindow).isModifiable && !serviceStep.isFixed,
        ...(currentService && ((isInIframe(window) && !serviceStep.isFixed) || !isInIframe(window)) && globalState.client.data && {
          link: `/${globalState.client.data._id}/booking/${currentService._id}/${i + 1}${jobId ? '/?job=' + jobId : ''}`
        })
      };
    });
  }, [
    jobId,
    globalState.client,
    globalState.service,
    currentService
  ]);

  const isReadonly = useMemo(() => {
    return !!globalState.job.data?.byAdmin && globalState.job.data?.price > 0 && step.name !== 'payment';
  }, [
    step,
    globalState.job.data
  ]);

  const getStepSchema = useCallback((incomingStep: Step) => {
    const schema: AnyObject = {};

    incomingStep.fields.forEach((field: Field, i: number) => {
      switch (field.type) {
        case FieldType.ShortText:
        case FieldType.LongText:
        case FieldType.Telephone:
          schema[field.name] = string().label(field.label);
          break;
        case FieldType.Dropdown:
          schema[field.name] = mixed().label(field.label);
          break;
        case FieldType.Email:
          schema[field.name] = string().email().label(field.label)
          break;
        case FieldType.Number:
          schema[field.name] = number().label(field.label);
          break;
        case FieldType.DateTime:
          schema[field.name] = array().label(field.label).min(2)
            .test('timeslot-chosen', 'Please choose a timeslot', (value?: string[]) => {
              if (!value) {
                return false;
              }

              return value[0] !== value[1];
            });
          break;
        default:
          schema[field.name] = mixed().label(field.label);
      };

      if (field.optional) {
        schema[field.name] = schema[field.name].optional();
      } else {
        schema[field.name] = schema[field.name].required();
      }
    });

    return object(schema);
  }, []);

  // Set validation schema
  useMemo(() => {
    const schema = getStepSchema(step);

    reset();
    setSchema(schema);
  }, [
    step,
    reset,
    setSchema,
    getStepSchema
  ]);

  const sendSelectStepEvent = useCallback((stepIndex: number) => {
    if (!isInIframe(window)) {
      return;
    }

    const data = {
      action: FrameActions.SELECT_STEP,
      to: 'parent',
      payload: {
        stepIndex
      }
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, []);

  const isPenultimateStep = useMemo(() => {
    return globalState.service.data!.steps[index] && globalState.service.data!.steps[index].name === 'payment';
  }, [
    index,
    globalState.service.data
  ]);

  const continueToNextPage = useCallback(() => {
    if (isInIframe(window)) {
      sendSelectStepEvent(index);
    } else {
      navigate({
        pathname: `/${params.clientId}/booking/${params.serviceId}/${index + 1}`,
        ...(jobId && {
          search: `?job=${jobId}`
        })
      }, { replace: true });
    }
  }, [
    navigate,
    params.clientId,
    params.serviceId,
    index,
    jobId,
    sendSelectStepEvent
  ]);

  const continueToPaymentPage = useCallback((job: string, replace?: boolean) => {
    if (replace) {
      return navigate(`/${params.clientId}/booking/${params.serviceId}/${index}?job=${job}`, { replace: true });
    }

    navigate({
      pathname: `/${params.clientId}/booking/${params.serviceId}/${index + 1}/`,
      search: `?job=${job}`
    }, { replace: true });
  }, [
    navigate,
    params.clientId,
    params.serviceId,
    index
  ]);

  const continueToCompletePage = useCallback(() => {
    navigate({
      pathname: `/${params.clientId}/booking/complete`
    }, { replace: true });
  }, [
    navigate,
    params.clientId
  ]);

  const navigateToClientListingPage = useCallback(() => {
    navigate({
      pathname: `/${params.clientId}/booking`
    });
  }, [
    params.clientId,
    navigate
  ]);

  // Ensure steps can't be skipped
  const isPreviousStepComplete = useCallback(async (targetStep: ProgressStep): Promise<boolean> => {
    const steps = (globalState.service.data?.steps ?? []);

    let previousStep: Step | undefined;

    for (let i = 0; i < steps.length; i++) {
      const currentStep = steps[i];

      if (currentStep.name === targetStep.name) {
        const stepIndex = i - 1;

        previousStep = steps[stepIndex >= 0 ? stepIndex : 0];
        break;
      }
    }

    if (previousStep) {
      const stepSchema = getStepSchema(previousStep);

      try {
        const schemaValid = await validateSchema({
          incomingSchema: stepSchema,
          form: globalState.job.form[previousStep.name],
          propagateRejection: true
        });

        if (schemaValid) {
          return Promise.resolve(true);
        }
      } catch (e) {}
    }

    return Promise.resolve(false);
  }, [
    globalState.service.data,
    globalState.job.form,
    getStepSchema,
    validateSchema
  ]);

  const resetForm = useCallback(() => {
    dispatch({
      type: Actions.RESET_FORM,
      payload: serviceId!
    });
  }, [
    serviceId,
    dispatch
  ]);

  const onBack = useCallback(() => {
    if (isInIframe(window)) {
      if (atStart) {
        return;
      }

      return sendSelectStepEvent(index - 2);
    }

    if (atStart) {
      resetForm();
      navigateToClientListingPage();
    } else {
      if (step.name === 'payment' && state.chosenPaymentOption !==  PaymentOption.None) {
        return setState(prevState => ({
          ...prevState,
          chosenPaymentOption: PaymentOption.None
        }));
      }

      navigate({
        pathname: `/${params.clientId}/booking/${params.serviceId}/${index - 1}`,
        ...(jobId && {
          search: `?job=${jobId}`
        })
      }, { replace: true });
    }
  }, [
    step.name,
    navigate,
    atStart,
    index,
    jobId,
    state.chosenPaymentOption,
    params.clientId,
    params.serviceId,
    sendSelectStepEvent,
    resetForm,
    navigateToClientListingPage
  ]);

  const shouldUpdateJob = useCallback((): boolean => {
    if (!globalState.job.data || Object.keys(globalState.job.form).length === 0) {
      return false;
    }

    const jobPrice: number = globalState.job.data!.price;
    const jobFields: any = globalState.job.data!.fields;
    const previewPrice: number = state.previewPrice;
    const form: any = globalState.job.form;

    if (globalState.job.data && globalState.job.data.byAdmin && !form['payment']) {
      return false;
    }

    if (JSON.stringify(jobFields) !== JSON.stringify(form) || jobPrice !== previewPrice) {
      return true;
    }

    return false;
  }, [
    globalState.job,
    state.previewPrice
  ]);

  const upsertJob = useCallback((): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (jobId) {
        if (!shouldUpdateJob()) {
          return resolve(jobId);
        }

        setState(prevState => ({
          ...prevState,
          job: {
            ...prevState.job,
            loading: true,
            data: null,
            error: null
          }
        }));

        gatewayService.updateJob(
          params.clientId,
          jobId,
          {
            fields: {
              ...globalState.job.form
            }
          },
          true
        )
        .then((updateJobResponse: any) => {
          dispatch({
            type: Actions.SAVE_JOB,
            payload: updateJobResponse.data
          });

          setState(prevState => ({
            ...prevState,
            job: {
              ...prevState.job,
              loading: false,
            }
          }));

          resolve(updateJobResponse.data._id);
        })
        .catch((err: any) => {
          // console.log('--error submitting job');

          reject();

          setState(prevState => ({
            ...prevState,
            job: {
              ...prevState.job,
              loading: false,
              data: null,
              error: getNetworkErrors([err])[0]
            }
          }));
        });
      } else {
        setState(prevState => ({
          ...prevState,
          job: {
            ...prevState.job,
            loading: true,
            data: null,
            error: null
          }
        }));

        gatewayService.createJob(
          params.clientId,
          format(new Date(), ISO_FORMAT),
          {
            fields: {
              ...globalState.job.form
            },
            serviceId: params.serviceId,
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          true
        )
        .then((createJobResponse: any) => {
          dispatch({
            type: Actions.SAVE_JOB,
            payload: createJobResponse.data
          });

          setState(prevState => ({
            ...prevState,
            job: {
              ...prevState.job,
              loading: false,
            }
          }));

          resolve(createJobResponse.data._id);
        })
        .catch((err: any) => {
          // console.log('--error submitting job');

          reject();

          setState(prevState => ({
            ...prevState,
            job: {
              ...prevState.job,
              loading: false,
              data: null,
              error: getNetworkErrors([err])[0]
            }
          }));
        });
      }
    });
  }, [
    jobId,
    params.clientId,
    params.serviceId,
    globalState.job.form,
    dispatch,
    shouldUpdateJob
  ]);

  const updateJob = useCallback(async () => {
    await gatewayService
      .updateJob(
        params.clientId,
        jobId as string,
        {
          fields: {
            ...globalState.job.form
          }
        },
        true
      );
  }, [
    params.clientId,
    jobId,
    globalState.job.form
  ]);

  const onFormSubmit = useCallback(async (e: any) => {
    e.preventDefault();

    // if (isInIframe(window) && (progressSteps[index].name === 'date' || isPenultimateStep)) {
    if (isInIframe(window)) {
      continueToNextPage();

      return;
    }

    validate({
      form: globalState.job.form[step.name],
      all: true
    })
      .then(() => {
        if (isPenultimateStep) {
          return upsertJob()
            .then((id: string) => {
              continueToPaymentPage(id); 
            })
            .catch(() => {});
        }

        continueToNextPage();
      });
  }, [
    isPenultimateStep,
    step.name,
    globalState.job.form,
    upsertJob,
    continueToNextPage,
    continueToPaymentPage,
    validate
  ]);

  const isDisabled = useCallback(() => {
    return false;
  }, []);

  const sendSelectFieldEvent = useCallback((field: Field | undefined, fieldIndex: number, value: any) => {
    if (!(isInIframe(window) && (window as BrowserWindow).isModifiable)) {
      return;
    }

    const data = {
      action: FrameActions.SELECT_FIELD,
      to: 'parent',
      payload: {
        field: {
          ...field,
          value
        },
        selectedStepIndex: index - 1,
        selectedFieldIndex: fieldIndex
      }
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, [index]);

  const sendEditFieldEvent = useCallback((field: Field, value: any) => {
    if (!isInIframe(window)) {
      return;
    }

    const data = {
      action: FrameActions.EDIT_FIELD,
      to: 'parent',
      payload: {
        ...field,
        value
      }
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, []);

  const sendDeleteFieldEvent = useCallback((field: Field, fieldIndex: number) => {
    if (!isInIframe(window)) {
      return;
    }

    const data = {
      action: FrameActions.DELETE_FIELD,
      to: 'parent',
      payload: {
        field,
        selectedStepIndex: index - 1,
        selectedFieldIndex: fieldIndex
      }
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, [index]);

  const sendReOrderedFieldsEvent = useCallback((dragIndex: number, hoverIndex: number) => {
    if (!isInIframe(window)) {
      return;
    }

    const data = {
      action: FrameActions.REORDER_FIELDS,
      to: 'parent',
      payload: {
        stepIndex: index - 1,
        dragIndex,
        hoverIndex
      }
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, [index]);

  const sendRemoveStepEvent = useCallback((stepIndex: number) => {
    if (!isInIframe(window)) {
      return;
    }

    const data = {
      action: FrameActions.REMOVE_STEP,
      to: 'parent',
      payload: {
        stepIndex
      }
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, []);

  const sendPriceResultsEvent = useCallback((priceResults: any) => {
    if (!isInIframe(window)) {
      return;
    }

    const data = {
      action: FrameActions.PRICE_RESULTS,
      to: 'parent',
      payload: priceResults
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, []);

  const sendEnableFieldPricingEvent = useCallback((field: Field | undefined) => {
    if (!(isInIframe(window) && (window as BrowserWindow).isModifiable)) {
      return;
    }

    const data = {
      action: FrameActions.ENABLE_FIELD_PRICING,
      to: 'parent',
      payload: { field }
    };

    window.postMessage(JSON.stringify(data), window.location.origin);
  }, []);

  const updatePrice = useCallback((price: number) => {
    setState(prevState => ({
      ...prevState,
      previewPrice: price
    }));
  }, []);

  const [onCalculateJob] = useDebouncedCallback((fields?: any) => {
    if (state.calculation.loading || (isInIframe(window) && !globalState.service.builder.testMode)) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      calculation: {
        ...prevState.calculation,
        loading: true
      }
    }));

    gatewayService
      .calculate(
        params.clientId,
        {
          serviceId: params.serviceId,
          fields: fields || getFlattenFormFields(globalState.job.form)
        },
        !isInIframe(window)
      )
      .then((priceResponse: any) => {
        // TODO: check that the component is still mounted otherwise exit

        setState(prevState => ({
          ...prevState,
          calculation: {
            ...prevState.calculation,
            loading: false,
            data: priceResponse.data
          }
        }));

        updatePrice(priceResponse.data.price);

        if (isInIframe(window)) {
          sendPriceResultsEvent(priceResponse.data);
        }
      })
      .catch((err: any) => {
        // console.log('--error calculating job');

        setState(prevState => ({
          ...prevState,
          calculation: {
            ...prevState.calculation,
            loading: false,
            error: getNetworkErrors([err])[0]
          }
        }));
      });
  }, 500);

  const onChangeFormField = useCallback((e: any, key: string, value?: any, calculate?: boolean) => {
    const localValue = value || value === 0 ? value : e.target.value;
    const isCurrency: boolean = e.target.getAttribute('type') === FieldType.Currency;

    // TODO: nest form data under serviceId as a key in localstorage

    const newKeyValue: any = {
      [key]: isCurrency ? Number(getRawCurrencyValue(localValue)) : localValue
    };

    dispatch({
      type: Actions.SAVE_JOB_FORM,
      payload: {
        step: step.name,
        fields: newKeyValue
      }
    });

    if (calculate && ((globalState.job.form[step.name] && localValue !== globalState.job.form[step.name][key]) || !globalState.job.form[step.name])) {
      onCalculateJob(getFlattenFormFields(globalState.job.form, newKeyValue));
    }

    const fieldIndex: number = step.fields.findIndex((fieldItem: Field) => fieldItem.name === key)!;

    sendSelectFieldEvent(
      step.fields[fieldIndex],
      fieldIndex,
      localValue
    );
  }, [
    step,
    globalState.job.form,
    dispatch,
    onCalculateJob,
    sendSelectFieldEvent
  ]);

  const onChangeBookingSelectField = useCallback((key: string, option: any, calculate?: boolean) => {
    const localValue = (option && option.value) || '';

    const newKeyValue: any = {
      [key]: localValue
    };

    dispatch({
      type: Actions.SAVE_JOB_FORM,
      payload: {
        step: step.name,
        fields: newKeyValue
      }
    });

    if (calculate && ((globalState.job.form[step.name] && localValue !== globalState.job.form[step.name][key]) || !globalState.job.form[step.name])) {
      onCalculateJob(getFlattenFormFields(globalState.job.form, newKeyValue));
    }

    const fieldIndex: number = step.fields.findIndex((fieldItem: Field) => fieldItem.name === key)!;

    sendSelectFieldEvent(
      step.fields[fieldIndex],
      fieldIndex,
      (option && option.value) || ''
    );
  }, [
    step,
    globalState.job.form,
    dispatch,
    onCalculateJob,
    sendSelectFieldEvent
  ]);

  const fetchUnavailableDates = useCallback((isoDate: string) => {
    if (isInIframe(window) && (window as BrowserWindow).isModifiable) {
      return;
    }

    if (state.unavailableDates.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      unavailableDates: {
        ...prevState.unavailableDates,
        loading: true
      }
    }));

    gatewayService.getUnavailableDates(
      params.clientId,
      isoDate,
      params.serviceId,
      true
    )
      .then((datesResponse: any) => {
        setState(prevState => ({
          ...prevState,
          unavailableDates: {
            loading: false,
            data: [
              ...datesResponse.data
            ],
            error: null
          }
        }));
      })
      .catch((err: any) => {
        setState(prevState => ({
          ...prevState,
          unavailableDates: {
            ...prevState.unavailableDates,
            loading: false,
            data: undefined,
            error: getNetworkErrors([err])[0]
          }
        }));
        // TODO: show error
      });
  }, [
    state.unavailableDates.loading,
    params.clientId,
    params.serviceId
  ]);

  const fetchTimeSlots = useCallback((date: string) => {
    if (isInIframe(window) && (window as BrowserWindow).isModifiable) {
      return;
    }

    if (state.timeSlots.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      timeSlots: {
        ...prevState.timeSlots,
        loading: true
      }
    }));

    gatewayService.getTimeSlots(
      params.clientId,
      date,
      params.serviceId,
      true
    )
      .then((timeSlotsResponse: any) => {
        setState(prevState => ({
          ...prevState,
          timeSlots: {
            loading: false,
            data: timeSlotsResponse.data,
            error: null
          }
        }));
      })
      .catch((err: any) => {
        setState(prevState => ({
          ...prevState,
          timeSlots: {
            ...prevState.timeSlots,
            loading: false,
            data: null,
            error: getNetworkErrors([err])[0]
          }
        }));
        // TODO: show error
      });
  }, [
    state.timeSlots.loading,
    params.clientId,
    params.serviceId
  ]);

  const getPaymentIntent = useCallback(async () => {
    if (state.paymentIntent.loading) {
      return;
    }

    setState(prevState => ({
      ...prevState,
      paymentIntent: {
        ...prevState.paymentIntent,
        loading: true
      }
    }));

    // const jobId: string | void = await beforeGetPaymentIntent();
    const upsertJobId: string | void = await upsertJob()
      .then((id: string) => {
        if (!jobId) {
          continueToPaymentPage(id, true);
        }

        return id;
      })
      .catch(() => {});

    if (!upsertJobId) {
      // TODO: show error
      // console.log('---------beforeGetPaymentIntent returned null');
      return;
    }

    gatewayService
      .getPaymentIntent(
        params.clientId,
        jobId as string,
        true
      )
      .then((paymentIntentResponse: any) => {
        // console.log('---paymentIntent', paymentIntentResponse.data);

        setState(prevState => ({
          ...prevState,
          paymentNeeded: paymentIntentResponse.data.amount,
          paymentIntent: {
            ...prevState.paymentIntent,
            loading: false,
            data: paymentIntentResponse.data,
            error: null
          },
          upsertJobId
        }));

        // onPaymentNeeded(paymentIntentResponse.data.amount);
        // onPriceUpdate(paymentIntentResponse.data.baseAmount);
        updatePrice(paymentIntentResponse.data.baseAmount);
      })
      .catch((err: any) => {
        setState(prevState => ({
          ...prevState,
          paymentIntent: {
            ...prevState.paymentIntent,
            loading: false,
            data: null,
            error: getNetworkErrors([err])[0]
          }
        }));
      });
  }, [
    jobId,
    params.clientId,
    state.paymentIntent.loading,
    upsertJob,
    updatePrice,
    continueToPaymentPage
  ]);

  const reOrder = useCallback((dragIndex: number, hoverIndex: number) => {
    sendReOrderedFieldsEvent(dragIndex, hoverIndex);
  }, [sendReOrderedFieldsEvent]);

  const removeStep = useCallback((progressStep: ProgressStep) => {
    if (!globalState.service.data) {
      return;
    }

    const stepIndex: number = globalState.service.data.steps.findIndex(s => s.name === progressStep.name);

    sendRemoveStepEvent(stepIndex);
  }, [
    globalState.service,
    sendRemoveStepEvent
  ]);

  // TODO: change to filter out steps not editable
  const filterOutFieldsNotEditable = useCallback((field: Field) => {
    return true;
  }, []);

  const renderFields = useCallback(() => {
    const isMultiField: boolean = globalState.service.builder.selectedFieldIndex === -1 && globalState.service.builder.selectedFields.length > 1;
    let selectedField: Field | null | undefined = isMultiField ? null : globalState.service.builder.field;

    return (
      <FormSection
        noBorder
        cols={1}
      >
        <fieldset>
          {step.fields.map((fieldItem: Field, i: number) => {
            // If isMultiField or not found by fieldIndex, check by selectedFields
            if (isMultiField || !selectedField) {
              selectedField = globalState.service.builder.selectedFields.find((field) => field.id === fieldItem.id);
            }

            const isSelectedField: boolean = !!selectedField && selectedField.id === fieldItem.id;
            const value = (isInIframe(window) && globalState.service.builder.testMode === false ? isSelectedField ? selectedField!.value : '' : globalState.job.form[step.name] && globalState.job.form[step.name][fieldItem.name]) || '';
            const calculate: boolean | undefined = fieldItem.calculateAfterChange;

            let elem: React.ReactNode;
            let label: string | any = fieldItem.label;

            if (fieldItem.unitPrice) {
              label = (
                <span>{label} (<i><b>{formatCurrency(fieldItem.unitPrice)} each</b> {fieldItem.unitPriceLabel ?? ''}</i>)</span>
              );
            }

            const editProps: any = isInIframe(window) && (window as BrowserWindow).isModifiable && filterOutFieldsNotEditable(fieldItem) && !step.isFixed ? {
              builder: {
                selected: isSelectedField,
                isSelectable: globalState.service.builder.context === 'pricing' ? isFieldCalculable(fieldItem) && globalState.service.builder.isSelectable : globalState.service.builder.isSelectable,
                isEditable: isSelectedField && globalState.service.builder.isEditable,
                isSortable: isSelectedField && globalState.service.builder.isSortable,
                isDeletable: isSelectedField && globalState.service.builder.isDeletable,
                isMultiple: globalState.service.builder.context === 'pricing' && fieldItem.type === FieldType.Number && !fieldItem.unitPrice,
                onEdit: (e: any) => {
                  e.stopPropagation();

                  sendEditFieldEvent(fieldItem, value);
                },
                onDelete: (e: any) => {
                  e.stopPropagation();

                  sendDeleteFieldEvent(fieldItem, i);
                },
                onEditWrapperClick: () => {
                  sendSelectFieldEvent(fieldItem, i, value);
                },
                onIsMultipleClick: (e: any) => {
                  e.stopPropagation();

                  sendEnableFieldPricingEvent(fieldItem);
                }
              },
              onFocus: () => {
                sendSelectFieldEvent(fieldItem, i, value);
              },
              ...(!globalState.service.builder.testMode && {
                onBlur: () => {}
              })
            } : {};

            if (fieldItem.name.includes('postcode')) {
              return (
                <FormLine key={i} marginBottom>
                  <PostcodeLookup
                    name={'bliiling-address-postcode'}
                    width={'100%'}
                    value={value}
                    error={errors[fieldItem.name]}
                    label={label}
                    isDisabled={isReadonly}
                    onChange={(e) => onChangeFormField(e, fieldItem.name)}
                    onSelected={(address: any) => {
                      // console.log('---address', address);

                      dispatch({
                        type: Actions.SAVE_JOB_FORM,
                        payload: {
                          step: step.name,
                          fields: {
                            ...(step.name === 'address' && {
                              'address-line-1': address.line_1,
                              'address-line-2': address.line_2,
                              'address-line-3': address.line_3.length > 0 ? address.line_3 : address.line_4.length === 0 ? address.locality : address.line_4,
                              city: address.town_or_city
                            }),
                            ...(step.name === 'payment' && {
                              'billing-address-line-1': address.line_1,
                              'billing-address-line-2': address.line_2,
                              'billing-address-line-3': address.line_3.length > 0 ? address.line_3 : address.line_4.length === 0 ? address.locality : address.line_4,
                              'billing-address-city': address.town_or_city
                            })
                          }
                        }
                      });
                    }}
                    onBlur={() => {
                      if (isInIframe(window) && !globalState.service.builder.testMode) {
                        return;
                      }

                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                  />
                </FormLine>
              );
            }

            switch (fieldItem.type) {
              case FieldType.ShortText:
                elem = (
                  <TextInput
                    width={'100%'}
                    value={value}
                    label={label}
                    placeholder={fieldItem.hint}
                    info={fieldItem.info}
                    error={errors[fieldItem.name]}
                    disabled={isReadonly}
                    onChange={(e) => onChangeFormField(e, fieldItem.name, null, calculate)}
                    onBlur={() => {
                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                    {...editProps}
                  />
                );
                break;
              case FieldType.LongText:
                elem = (
                  <TextArea
                    width={'100%'}
                    value={value}
                    label={label}
                    placeholder={fieldItem.hint}
                    info={fieldItem.info}
                    error={errors[fieldItem.name]}
                    disabled={isReadonly}
                    onChange={(e) => onChangeFormField(e, fieldItem.name, null, calculate)}
                    onBlur={() => {
                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                    {...editProps}
                  />
                );
                break;
              case FieldType.Email:
                elem = (
                  <TextInput
                    width={'100%'}
                    type={'email'}
                    value={value}
                    label={label}
                    placeholder={fieldItem.hint}
                    info={fieldItem.info}
                    error={errors[fieldItem.name]}
                    onChange={(e) => onChangeFormField(e, fieldItem.name, null, calculate)}
                    onBlur={() => {
                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                    {...editProps}
                  />
                );
                break;
              case FieldType.Telephone:
                elem = (
                  <TextInput
                    width={'100%'}
                    type={'telephone'}
                    value={value}
                    label={label}
                    placeholder={fieldItem.hint}
                    info={fieldItem.info}
                    error={errors[fieldItem.name]}
                    onChange={(e) => onChangeFormField(e, fieldItem.name, null, calculate)}
                    onBlur={() => {
                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                    {...editProps}
                  />
                );
                break;
              case FieldType.Number:
                elem = (
                  <NumericInput
                    width={'100%'}
                    value={value}
                    min={fieldItem.min}
                    max={fieldItem.max}
                    label={label}
                    placeholder={fieldItem.hint}
                    info={fieldItem.info}
                    error={errors[fieldItem.name]}
                    disabled={isReadonly}
                    onUpdate={(e, val) => onChangeFormField(e, fieldItem.name, val, calculate)}
                    onBlur={() => {
                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                    {...editProps}
                  />
                );
                break;
              case FieldType.Dropdown:
                elem = (
                  <Dropdown
                    width={'100%'}
                    isClearable={false}
                    label={fieldItem.label}
                    value={value}
                    placeholder={fieldItem.hint}
                    info={fieldItem.info}
                    error={errors[fieldItem.name]}
                    isDisabled={isReadonly}
                    options={fieldItem.dropdownItems!}
                    onChange={(option: any) => onChangeBookingSelectField(fieldItem.name, option, calculate)}
                    onBlur={(e) => {
                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                    {...editProps}
                  />
                );
                break;
              case FieldType.DateTime:
                // Re-add job time slot to time slot options (if selected slot is out of range of slots etc)
                const selectedTimeSlot: string | null = value && value[0] !== value[1] ? value.join(' - ') : null;
                let timeSlotOptions: string[] = (state.timeSlots.data?.slots ?? []).map((slotItem: any) => slotItem.slot);

                if (selectedTimeSlot && timeSlotOptions && !timeSlotOptions.includes(selectedTimeSlot)) {
                  timeSlotOptions = [
                    selectedTimeSlot,
                    ...timeSlotOptions
                  ];
                }

                elem = (
                  <DateTimeFields
                    width={'100%'}
                    value={value}
                    loading={state.unavailableDates.loading}
                    slots={{
                      options: timeSlotOptions,
                      onFetchTimeSlots: fetchTimeSlots
                    }}
                    unavailableDates={state.unavailableDates.data}
                    withMinMaxDate={{
                      minInDays: globalState.service.data!.earliestBookingInDays,
                      maxInMonths: globalState.service.data!.latestBookingInMonths
                    }}
                    timeDuration={globalState.service.data!.maxDuration}
                    info={fieldItem.info}
                    error={errors[fieldItem.name]}
                    isDisabled={isReadonly}
                    onCalendarOpen={() => {
                      if (!(value as string[])?.length) {
                        return null;
                      }

                      const dateToFetch = value ? value[0] : format(new Date(), ISO_FORMAT);

                      fetchUnavailableDates(dateToFetch)
                    }}
                    onChange={(datetime: Date | string[]) => {
                      // console.log('-------------options datetime', datetime);

                      dispatch({
                        type: Actions.SAVE_JOB_FORM,
                        payload: {
                          step: step.name,
                          fields: {
                            [fieldItem.name]: datetime
                          }
                        }
                      });
                    }}
                    onMonthChange={(date: Date) => {
                      const now = new Date();

                      if (isSameMonth(date, now)) {
                        fetchUnavailableDates(format(now, ISO_FORMAT));
                      } else {
                        fetchUnavailableDates(format(startOfMonth(date), ISO_FORMAT));
                      }
                    }}
                    onBlur={() => {
                      if (isInIframe(window) && !globalState.service.builder.testMode) {
                        return;
                      }

                      validate({
                        form: globalState.job.form[step.name],
                        field: fieldItem.name
                      });
                    }}
                  />
                );
                break;
            }

            if (isInIframe(window)) {
              return (
                <DraggableFormField
                  key={`${step.name}-${fieldItem.id}`}
                  canDrag={!!(globalState.service.builder.isSortable && editProps.builder?.selected)}
                  content={elem}
                  fieldItem={fieldItem}
                  index={i}
                  stepIndex={index}
                  onReOrder={reOrder}
                />
              );
            }

            return (
              <FormLine
                key={fieldItem.id}
                marginBottom
                column
              >
                {elem}
              </FormLine>
            );
          })}
        </fieldset>
        {state.unavailableDates.error && (
          <FormLine marginBottom>
            <Text isError value={state.unavailableDates.error.message} />
          </FormLine>
        )}
        {state.timeSlots.error && (
          <FormLine marginBottom>
            <Text isError value={state.timeSlots.error.message} />
          </FormLine>
        )}
        {state.job.error && (
          <FormLine marginBottom>
            <Text isError value={state.job.error.message} />
          </FormLine>
        )}
        {state.calculation.error && (
          <FormLine marginBottom>
            <Text isError value={state.calculation.error.message} />
          </FormLine>
        )}
      </FormSection>
    );
  }, [
    state,
    index,
    step.name,
    step.fields,
    step.isFixed,
    globalState.service.builder,
    globalState.service.data,
    globalState.job.form,
    errors,
    isReadonly,
    sendSelectFieldEvent,
    sendEditFieldEvent,
    sendDeleteFieldEvent,
    sendEnableFieldPricingEvent,
    onChangeBookingSelectField,
    onChangeFormField,
    fetchTimeSlots,
    reOrder,
    dispatch,
    filterOutFieldsNotEditable,
    fetchUnavailableDates,
    validate
  ]);

  const renderACHTransferMethod = useCallback(() => {
    const sortCode: string = globalState.client.data?.settings.accounting.businessSortCode ?? '';
    const sortCodeFormatted: string = `${sortCode.substring(0, 2)}-${sortCode.substring(2, 4)}-${sortCode.substring(4, 6)}`;

    return (
      <>
        <FormLine
          column
          marginBottom
        >
          <FormLine marginBottom>Please use the following details to pay for the booking:</FormLine>
          <div>
            <b>{globalState.client.data?.businessName}</b>
          </div>
          <AccountDetails>
            <div>
              <div><b>Account:</b></div>
              <div><b>{globalState.client.data?.settings.accounting.businessAccountNumber}</b></div>
            </div>
            <div>
              <div><b>Sort code:</b></div>
              <div><b>{sortCodeFormatted}</b></div>
            </div>
            <div>
              <div><b>Reference:</b></div>
              <div><b>{getBookingInvoiceNumber(globalState.job.data!)}</b></div>
            </div>
          </AccountDetails>
        </FormLine>
        <FormLine marginBottom />
        <FormLine marginBottom>
          <HR />
        </FormLine>
        <FormLine topPadding>
          <Button
            type="button"
            onClick={onBack}
          >{atStart ? 'Cancel' : 'Back'}</Button>
        </FormLine>
      </>
    );
  }, [
    globalState.client.data,
    globalState.job.data,
    atStart,
    onBack
  ]);

  const renderCustomerPaymentService = useCallback(() => {
    return (
      <>
        {renderFields()}
        {globalState.client.data
          && (state.paymentNeeded === null || state.paymentNeeded > 0)
          && (
            <FormSection cols={1} noBorder>
              <fieldset>
                <FormLine marginBottom column>
                  <Payment
                    mock={isInIframe(window)}
                    upsertJobId={state.upsertJobId}
                    paymentIntent={state.paymentIntent}
                    client={globalState.client.data}
                    form={globalState.job.form[step.name]}
                    beforePay={async (): Promise<void> => {
                      return new Promise((resolve, reject) => {
                        validate({
                          form: globalState.job.form[step.name],
                          all: true,
                          propagateRejection: true
                        })
                          .then(async () => {
                            await updateJob();

                            resolve();
                          })
                          .catch(() => {
                            reject();
                          });
                      });
                    }}
                    afterPay={(error?: any) => {
                      if (error) {
                        // console.log('---error', error);

                        return;
                      }

                      continueToCompletePage();
                      resetForm();
                    }}
                    onBack={onBack}
                  />
                </FormLine>
              </fieldset>
            </FormSection>
          )
        }
      </>
    );
  }, [
    globalState.job.form,
    globalState.client.data,
    step.name,
    state.upsertJobId,
    state.paymentNeeded,
    state.paymentIntent,
    updateJob,
    validate,
    resetForm,
    onBack,
    continueToCompletePage,
    renderFields
  ]);

  const renderPaymentOptions = useCallback(() => {
    const [status, paymentOptions] = canCustomerMakePayment(globalState.client.data);

    if (!status) {
      return (
        <div>There are no payment options available. Please contact the business owner.</div>
      );
    }

    if (state.chosenPaymentOption === PaymentOption.None) {
      return (
        <>
          <FormLine marginBottom>Choose payment method:</FormLine>
          <FormLine
            column
            marginBottom
          >
            {paymentOptions.map((paymentOption) => {
              return (
                <FormLine
                  marginBottom
                  key={paymentOption}
                >
                  <Card
                    row
                    centerH
                    centerV
                    pointer
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        chosenPaymentOption: paymentOption
                      }));
                    }}
                  >
                    <IconWrapper style={{ marginRight: '.5rem' }}>
                      {getPaymentOptionIcon(paymentOption)}
                    </IconWrapper>
                    <span>{paymentOption}</span>
                  </Card>
                </FormLine>
              );
            })}
          </FormLine>
          <FormLine marginBottom>
            <HR />
          </FormLine>
          <FormLine topPadding>
            <Button
              type="button"
              onClick={onBack}
            >{atStart ? 'Cancel' : 'Back'}</Button>
          </FormLine>
        </>
      );
    }

    let elem = null;
    let icon = null;

    switch (state.chosenPaymentOption) {
      case PaymentOption.CustomerPaymentMethod:
        elem = (
          renderCustomerPaymentService()
        );
        icon = <BillingIcon />;
        break;
      case PaymentOption.ACHTransfer:
        elem = (
          renderACHTransferMethod()
        );
        icon = <BankIcon />;
        break;
      default:
        return elem;
    };

    return (
      <>
        <FormLine
          row
          centerV
          marginBottom
          style={{ cursor: 'pointer' }}
          onClick={onBack}
        >
          <span>Chosen option:</span>
          <IconWrapper style={{
            marginLeft: '.5rem',
            justifyContent: 'left'
          }}>
            {icon}
          </IconWrapper>
        </FormLine>
        {elem}
      </>
    );
  }, [
    atStart,
    onBack,
    globalState.client.data,
    state.chosenPaymentOption,
    renderCustomerPaymentService,
    renderACHTransferMethod
  ]);

  const renderPayment = useCallback(() => {
    if (state.paymentNeeded === null && !isInIframe(window)) {
      return (
        <Spinner
          color={theme.textColor}
          size={'M'}
        />
      );
    }
    else if ((state.paymentNeeded! > 0 || isInIframe(window))) {
      return (
        <div>
          <FormLine marginBottom>
            <b>
              <h3>Amount due: {formatCurrency(state.paymentNeeded as number)}</h3>
            </b>
          </FormLine>
          <>
            {renderPaymentOptions()}
          </>
        </div>
      );
    }
    else if (state.paymentNeeded === 0) {
      return (
        <>
          <FormLine marginBottom>
            <span>There are no outstanding payments for this job</span>
          </FormLine>
          <FormLine>
            <Button
              type="button"
              onClick={onBack}
            >{atStart ? 'Cancel' : 'Back'}</Button>
          </FormLine>
        </>
      );
    }
  }, [
    state.paymentNeeded,
    atStart,
    onBack,
    renderPaymentOptions
  ]);

  const renderStep = useCallback(() => {
    if (step.name === 'payment') {
      return renderPayment();
    }

    return (
      <>
        {renderFields()}

        <FormLine marginBottom>
          <HR />
        </FormLine>

        <FormLine right topPadding spaceBetween>
          <Button
            type="button"
            onClick={onBack}
          >{atStart ? 'Cancel' : 'Back'}</Button>
          <PrimaryButton
            type="submit"
            loading={state.job.loading}
            disabled={isDisabled()}
          >Continue</PrimaryButton>
        </FormLine>
      </>
    );
  }, [
    state,
    step,
    atStart,
    isDisabled,
    onBack,
    renderFields,
    renderPayment
  ]);

  const renderForm = useCallback(() => {
    return (
      <BookingFormWrapper
        noOverflow
        onSubmit={onFormSubmit}
      >
        {renderStep()}
      </BookingFormWrapper>
    );
  }, [
    onFormSubmit,
    renderStep
  ]);

  const getFloatingLabel = useCallback(() => {
    let builderPrice: number = 0;

    if (!globalState.service.builder.selectedPrice?.appliesTo && globalState.service.builder.selectedPrice?.unitPrice) {
      builderPrice = globalState.service.builder.selectedPrice.unitPrice;
    }

    return (
      <div>
        <span>{currentService?.name}</span>
        {currentService?.name && (
          <br />
        )}
        {isInIframe(window) && !globalState.service.builder.testMode ? (
          <b>
            <span>{formatCurrency(builderPrice)}</span>
          </b>
        ) : (
          <b>
            <span ref={floatingLabelRef}>{formatCurrency(0)}</span>
          </b>
        )}
      </div>
    );
  }, [
    currentService,
    globalState.service.builder.testMode,
    globalState.service.builder.selectedPrice
  ]);

  const overwriteBrowserBackButton = useCallback(() => {
    if (step.name === 'payment') {
      // console.log('--overwriteBrowserBackButton');

      onBack();
    }
  }, [
    step.name,
    onBack
  ]);

  const addPopstateEventListener = useCallback(() => {
    if (!isInIframe(window)) {
      window.addEventListener('popstate', overwriteBrowserBackButton, false);
    }
  }, [overwriteBrowserBackButton]);

  const removePopstateEventListener = useCallback(() => {
    if (!isInIframe(window)) {
      window.removeEventListener('popstate', overwriteBrowserBackButton, false);
    }
  }, [overwriteBrowserBackButton]);

  // In the case of direct navigation to a step; on first load check if other steps are valid 
  // if not then redirect user to earliest step to continue from there
  const validateAndRedirectOnLoad = useCallback(() => {
    if (isInIframe(window)) {
      return;
    }

    if (jobId && !globalState.job.data) {
      return;
    }

    if (firstLoad) {
      // console.log('--checking');
      const promises: Array<Promise<boolean>> = [];

      (globalState.service.data?.steps ?? [])
        .filter((s: Step) => s.name !== 'payment')
        .forEach((s: Step) => {
          const stepSchema = getStepSchema(s);

          promises.push(
            validateSchema({
              incomingSchema: stepSchema,
              form: globalState.job.form[s.name],
              propagateRejection: true
            })
          );
        });

      Promise
        .allSettled(promises)
        .then((results) => {
          if (!results.every(r => r.status === 'fulfilled')) {
            const earliestStepIndex: number = results.findIndex(r => r.status === 'rejected');

            if (earliestStepIndex !== -1 && earliestStepIndex !== index - 1) {
              console.log(`--Requested ${index}, redirecting to ${earliestStepIndex + 1}`);

              navigate({
                pathname: `/${params.clientId}/booking/${params.serviceId}/${earliestStepIndex + 1}`,
                ...(jobId && {
                  search: `?job=${jobId}`
                })
              }, { replace: true });
            }
          }
        });

      firstLoad = false;
    }
  }, [
    index,
    jobId,
    params.clientId,
    params.serviceId,
    globalState.job.form,
    globalState.job.data,
    globalState.service.data,
    getStepSchema,
    validateSchema,
    navigate
  ]);

  useEffect(() => {
    const currentShownPrice = Number(getRawCurrencyValue((floatingLabelRef.current && floatingLabelRef.current.innerHTML) || ''));

    animateValue(
      currentShownPrice,
      state.previewPrice,
      1250,
      (value: number) => {
        if (floatingLabelRef.current) {
          floatingLabelRef.current!.innerHTML = formatCurrency(value);
        }
      },
    );
  }, [state.previewPrice]);

  // Switch to step selected in the builder
  useEffect(() => {
    if (globalState.service.data && globalState.service.builder.selectedStepIndex !== -1 && isInIframe(window)) {
      const selectedStep: Step = globalState.service.data.steps[globalState.service.builder.selectedStepIndex];

      if (step.name !== selectedStep.name) {
        navigate({
          pathname: `/${params.clientId}/booking/${params.serviceId}/${globalState.service.builder.selectedStepIndex + 1}`,
        }, { replace: true });
      }
    }
  }, [
    index,
    navigate,
    step.name,
    params.clientId,
    params.serviceId,
    globalState.service.data,
    globalState.service.builder.selectedStepIndex
  ]);

  useEffect(() => {
    if (params.serviceId !== 'none' && step.name !== 'payment' && ((prevParentReady === false && globalState.service.builder.parentReady) || prevParentReady === null)) {
      if (!state.calculation.data && !state.calculation.error) {
        onCalculateJob();
      }
    }
  }, [
    step.name,
    params.serviceId,
    state.calculation,
    prevParentReady,
    globalState.service.builder.parentReady,
    onCalculateJob
  ]);

  useEffect(() => {
    if (!isInIframe(window)
      && !state.timeSlots.loading
      && !state.timeSlots.data
      && !state.timeSlots.error
      && step.name === 'date'
    ) {
      fetchTimeSlots(format(new Date(), ISO_FORMAT));
    }
  }, [
    state.timeSlots.loading,
    state.timeSlots.data,
    state.timeSlots.error,
    step.name,
    fetchTimeSlots
  ]);

  useEffect(() => {
    if (
      !isInIframe(window)
        && step.name === 'payment'
        && !state.upsertJobId
        && !state.paymentIntent.data
        && !state.paymentIntent.error
    ) {
      getPaymentIntent();
    }
  }, [
    step.name,
    state.upsertJobId,
    state.paymentIntent.data,
    state.paymentIntent.error,
    getPaymentIntent
  ]);

  useEffect(() => {
    if (isReadonly) {
      // console.log('---restricted viewing');

      updatePrice(globalState.job.data!.price);
    }
  }, [
    globalState.job,
    isReadonly,
    updatePrice
  ]);

  useEffect(() => {
    if (prevParentReady !== globalState.service.builder.parentReady) {
      setPrevParentReady(globalState.service.builder.parentReady);
    }
  }, [
    globalState.service.builder.parentReady,
    prevParentReady
  ]);

  useEffect(() => {
    if (globalState.service.builder.hideBackground) {
      document.body.style.backgroundColor = 'transparent';
    }
  }, [
    globalState.service.builder.hideBackground
  ]);

  useEffect(() => {
    addPopstateEventListener();

    return () => {
      removePopstateEventListener();
    };
  }, [
    addPopstateEventListener,
    removePopstateEventListener
  ]);

  useEffect(() => {
    validateAndRedirectOnLoad();
  }, [validateAndRedirectOnLoad]);

  // console.log('------------------state', state, currentService, globalState);

  if (state.paymentIntent.error) {
    return (
      <ErrorPage errors={[state.paymentIntent.error]}/>
    );
  }

  return (
    <>
      {!globalState.service.builder.hideBackground && (
        <Background />
      )}
      <Wrapper>
        <Card
          boxShadow
          style={{
            ...(!globalState.service.builder.hideBackground && {
              marginTop: '5rem'
            }),
            marginBottom: '5rem'
          }}
        >
          <Progress
            activeStep={index - 1}
            steps={progressSteps}
            floatingLabel={getFloatingLabel()}
            errorSteps={stepHasErrors ? [step.name] : []}
            onCurrentStepWasPreviouslyTruncated={(currentStep) => {
              // Set price if truncated step comes into view since reference to the previous innerHTML content of floatingLabelRef will be lost
              if (floatingLabelRef.current) {
                floatingLabelRef.current!.innerHTML = formatCurrency(state.previewPrice);
              }
            }}
            onStepClick={async (e: any, { step: s, linkConfig }) => {
              if (isReadonly || step.name === 'payment') {
                return;
              }

              try {
                if (linkConfig.link) {
                  e.preventDefault();

                  await validate({
                    form: globalState.job.form[step.name],
                    all: true,
                    propagateRejection: true
                  });

                  const previousStepComplete = await isPreviousStepComplete(s);

                  if (previousStepComplete) {
                    navigate({
                      pathname: linkConfig.link,
                      ...(linkConfig.search && {
                        search: linkConfig.search
                      })
                    }, { replace: true });
                  }

                }
              } catch (e) {}
            }}
            onRemoveStep={globalState.service.builder.isDeletable ? removeStep : undefined}
          />
          <InnerWrapper>
            <Header2>{step.label}</Header2>
            {renderForm()}
          </InnerWrapper>
        </Card>
        {!globalState.service.builder.hideFooter && (
          <FormLine
            row
            center
            marginBottom
            centerV
          >
            <Footer />
          </FormLine>
        )}
      </Wrapper>
    </>
  );
};

export default memo(Booking);

